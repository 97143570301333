import * as Yup from 'yup';

export const schemaFactory = integration =>
	Yup.object().shape({
		forename: Yup.string().when([], {
			is: () => integration.checkForename,
			then: Yup.string().required('required'),
		}),
		surname: Yup.string().when([], {
			is: () => integration.checkSurname,
			then: Yup.string().required('required'),
		}),
		email: Yup.string()
			.email('invalid-email')
			.when([], {
				is: () => integration.checkEmail,
				then: Yup.string().required('required'),
			}),
		phone: Yup.string().when([], {
			is: () => integration.checkPhone,
			then: Yup.string().required('required'),
		}),
	});
