import React from 'react';
import { wire } from 'react-hot-wire';
import { createGlobalStyle } from 'styled-components';

// alwyn new rounded fonts:
import AlwynNewRoundedThin from 'assets/fonts/alwyn-new-rounded/AlwynNewRounded_Thin.woff';
import AlwynNewRoundedRegular from 'assets/fonts/alwyn-new-rounded/AlwynNewRounded_Regular.woff';
import AlwynNewRoundedRegularItalic from 'assets/fonts/alwyn-new-rounded/AlwynNewRounded_Regular_Italic.woff';
import AlwynNewRoundedMedium from 'assets/fonts/alwyn-new-rounded/AlwynNewRounded_Medium.woff';
import AlwynNewRoundedBold from 'assets/fonts/alwyn-new-rounded/AlwynNewRounded_Bold.woff';

// klavika fonts:
import KlavikaThin from 'assets/fonts/klavika/Klavika_Light.woff';
import KlavikaRegular from 'assets/fonts/klavika/Klavika_Regular.woff';
import KlavikaRegularItalic from 'assets/fonts/klavika/Klavika_Regular_Italic.woff';
import KlavikaMedium from 'assets/fonts/klavika/Klavika_Medium.woff';
import KlavikaBold from 'assets/fonts/klavika/Klavika_Bold.woff';

// pko fonts:
import PKORegular from 'assets/fonts/pko/PKO_Regular.woff2';

// work sans fonts:
import WorkSansLight from 'assets/fonts/work-sans/WorkSans_Light.woff2';
import WorkSansRegular from 'assets/fonts/work-sans/WorkSans_Regular.woff2';
import WorkSansItalic from 'assets/fonts/work-sans/WorkSans_Italic.woff2';
import WorkSansMedium from 'assets/fonts/work-sans/WorkSans_Medium.woff2';
import WorkSansBold from 'assets/fonts/work-sans/WorkSans_Bold.woff2';

// lidl fonts:
import LidlRegular from 'assets/fonts/lidl/LidlPro_Regular.woff2';
import LidlItalic from 'assets/fonts/lidl/LidlPro_Italic.woff2';
import LidlSemiBold from 'assets/fonts/lidl/LidlPro_Semi_Bold.woff2';
import LidlBold from 'assets/fonts/lidl/LidlPro_Bold.woff2';

// danone fonts:
import DanoneThin from 'assets/fonts/danone/Danone_Thin.woff2';
import DanoneRegular from 'assets/fonts/danone/Danone_Regular.woff2';
import DanoneItalic from 'assets/fonts/danone/Danone_Italic.woff2';
import DanoneBold from 'assets/fonts/danone/Danone_Bold.woff2';

// rossmann fonts:
import RossmannLight from 'assets/fonts/rossmann/Rossmann_Light.woff2';
import RossmannRegular from 'assets/fonts/rossmann/Rossmann_Regular.woff2';
import RossmannRegularItalic from 'assets/fonts/rossmann/Rossmann_Regular_Italic.woff2';
import RossmannMedium from 'assets/fonts/rossmann/Rossmann_Medium.woff2';
import RossmannBold from 'assets/fonts/rossmann/Rossmann_Bold.woff2';

function Fonts(props) {
	const applicationService = props['services.applicationService'];

	if (!applicationService) {
		return null;
	}

	const font = applicationService.settings().font();

	const fonts = {
		klavika: {
			thin: KlavikaThin,
			regular: KlavikaRegular,
			regularItalic: KlavikaRegularItalic,
			medium: KlavikaMedium,
			bold: KlavikaBold,
		},
		alwyn_new_rounded: {
			thin: AlwynNewRoundedThin,
			regular: AlwynNewRoundedRegular,
			regularItalic: AlwynNewRoundedRegularItalic,
			medium: AlwynNewRoundedMedium,
			bold: AlwynNewRoundedBold,
		},
		pko: {
			thin: PKORegular,
			regular: PKORegular,
			regularItalic: PKORegular,
			medium: PKORegular,
			bold: PKORegular,
		},
		work_sans: {
			thin: WorkSansLight,
			regular: WorkSansRegular,
			regularItalic: WorkSansItalic,
			medium: WorkSansMedium,
			bold: WorkSansBold,
		},
		lidl: {
			thin: LidlRegular,
			regular: LidlRegular,
			regularItalic: LidlItalic,
			medium: LidlSemiBold,
			bold: LidlBold,
		},
		danone: {
			thin: DanoneThin,
			regular: DanoneRegular,
			regularItalic: DanoneItalic,
			medium: DanoneBold,
			bold: DanoneBold,
		},
		rossmann: {
			thin: RossmannLight,
			regular: RossmannRegular,
			regularItalic: RossmannRegularItalic,
			medium: RossmannMedium,
			bold: RossmannBold,
		},
	};

	const fontFace = ({ font, fontWeight, customStyles }) => `
		@font-face {
			font-family: current-font;
			src: url('${font}') format('woff');
			font-weight: ${fontWeight};
			${customStyles}
		}
	`;

	const GlobalFonts = createGlobalStyle`
      ${fontFace({ font: fonts[font].thin, fontWeight: 100 })}
      ${fontFace({ font: fonts[font].regular, fontWeight: 400 })}
      ${fontFace({
			font: fonts[font].regularItalic,
			fontWeight: 400,
			customStyles: `
				font-style: italic;
			`,
		})}
      ${fontFace({ font: fonts[font].medium, fontWeight: 600 })}
      ${fontFace({ font: fonts[font].bold, fontWeight: 800 })}
    `;

	return <GlobalFonts />;
}

export default wire(['services.applicationService'], Fonts);
